import { useState } from "react";

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const useFetchList = () => {
    const [ data, setData ] = useState([]);

    const submit = async (url, header) => {

        const noBodyOption = {
            method: "POST",
            headers: header,
        }
    
        await wait(500);
        fetch(url, noBodyOption)
            .then(
                response => {
                    if ( response.status !== 401 ) {
                        // setData(response.json());
                        return response.json();
                    } else {
                        throw new Error("unauthorized");
                    }
                })
            .then(
                responseData => {
                    setData(responseData);
                }
            )
            .catch(error => {
                console.error('Error fetching data: ', error);
                setData([{
                    status: "error",
                    message: "unauthorized",
                }])
            });
    }; 
    return { data, submit };
};

export default useFetchList;