import "./UserSettingConsoleContent.css"
import { VStack, Text } from "@chakra-ui/react";
import UserContentHeader from "./UserContentHeader";

const UserSettingConsoleContent = () => {
    return (
        <div className="user-setting-console-container" >
            <UserContentHeader >
                <VStack width={"80%"}
                        height={"100%"}
                        align={"start"} >
                    <Text fontSize={"2xl"} as={'b'}>Setting Console</Text>
                </VStack>
            </UserContentHeader>
        </div>
    );
}

export default UserSettingConsoleContent;