import { VStack, Text, HStack } from "@chakra-ui/react";
import { FontAwesomeIcon }  from "@fortawesome/react-fontawesome";
import { faHouse, faDownload, faGears, faFile, faUserFriends, faReceipt } from "@fortawesome/free-solid-svg-icons";
// import { useNavigate } from "react-router-dom";
import "./UserLeftMenu.css";
import { useState, useEffect } from "react";

const blackLogo = require("../logos/logo-black.png");

const UserLeftMenu = ({ handleClickedMenu }) => {

    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    // const nav = useNavigate();

    const [ homeSelected, setHomeSelected ] = useState(true);
    const [ downloadSelected, setDownloadSelected ] = useState(false);
    const [ consoleSelected, setConsoleSelected ] = useState(false);
    const [ ordersSelected, setOrderSelected ] = useState(false);
    const [ redeemSelected, setRedeemSelected ] = useState(false);
    const [ referSelected, setReferSelected ] = useState(false);


    const handleClickHome = () => {
        handleClickedMenu(1);
        setHomeSelected(true);
        setDownloadSelected(false);
        setConsoleSelected(false);
        setOrderSelected(false);
        setRedeemSelected(false);
        setReferSelected(false)
    }

    const handleClickDownload = () => {
        handleClickedMenu(2);
        setDownloadSelected(true);
        setHomeSelected(false);
        setConsoleSelected(false);
        setOrderSelected(false);
        setRedeemSelected(false);
        setReferSelected(false)
    }

    const handleClickConsole = () => {
        handleClickedMenu(3);
        setConsoleSelected(true);
        setDownloadSelected(false);
        setHomeSelected(false);
        setOrderSelected(false);
        setRedeemSelected(false);
        setReferSelected(false)
    }

    const handleClickOrders = () => {
        handleClickedMenu(4);
        setOrderSelected(true);
        setHomeSelected(false);
        setDownloadSelected(false);
        setConsoleSelected(false);
        setRedeemSelected(false);
        setReferSelected(false)
    }

    const handleClickRedeem = () => {
        handleClickedMenu(5);
        setRedeemSelected(true);
        setOrderSelected(false);
        setHomeSelected(false);
        setDownloadSelected(false);
        setConsoleSelected(false);
        setReferSelected(false)
    }

    const handleClickRefer = () => {
        handleClickedMenu(6);
        setReferSelected(true)
        setRedeemSelected(false);
        setOrderSelected(false);
        setHomeSelected(false);
        setDownloadSelected(false);
        setConsoleSelected(false);
    }

    return (
        <VStack width={"17%"}
                height={"100%"}
                align={"start"} 
                paddingTop={"20px"} >

            <HStack height={"8%"}
                    width={"100%"}
                    paddingLeft={"25px"} 
                    marginBottom={"15px"}>

                <img src={blackLogo} width={"36px"} alt="blacklog" />
                {
                    screenWidth > 780 &&
                    <HStack>
                        <Text fontSize={"xl"} as={'b'} color={"#797979"}>CNNEX</Text>
                        <Text fontSize={"xl"} color={"gray"}>Account</Text>
                    </HStack>
                }
            </HStack>

            <HStack height={"60px"}
                    width={"100%"}
                    paddingLeft={"27px"}
                    spacing={10} 
                    className="home-menu" 
                    style={{backgroundColor: homeSelected && "#eeeeee"}}
                    onClick={handleClickHome} >
                <FontAwesomeIcon icon={faHouse} color={homeSelected ? "orange" : "gray"} size="lg" />
                {
                    screenWidth > 780 && <Text>Home</Text>
                }
            </HStack>

            <HStack height={"60px"}
                    width={"100%"}
                    paddingLeft={"27px"}
                    spacing={10} 
                    className="home-menu"
                    style={{backgroundColor: downloadSelected && "#eeeeee"}}
                    onClick={handleClickDownload}
                    >
                <FontAwesomeIcon icon={faDownload} color={downloadSelected ? "orange" : "gray"} size="lg" />
                {
                    screenWidth > 780 && <Text>Downloads</Text>
                }
            </HStack>

            <HStack height={"60px"}
                    width={"100%"}
                    paddingLeft={"27px"}
                    spacing={10} 
                    className="home-menu"
                    style={{backgroundColor: consoleSelected && "#eeeeee"}}
                    onClick={handleClickConsole}
                    >
                <FontAwesomeIcon icon={faGears} color={consoleSelected ? "orange" : "gray"} size="sm" />
                {
                    screenWidth > 780 && <Text>Setting Console</Text>
                }
            </HStack>

            <HStack height={"60px"}
                    width={"100%"}
                    paddingLeft={"27px"}
                    spacing={10} 
                    className="home-menu"
                    style={{backgroundColor: ordersSelected && "#eeeeee"}}
                    onClick={handleClickOrders}
                    >
                <FontAwesomeIcon icon={faReceipt} color={ordersSelected ? "orange" : "gray"} size="lg" />
                {
                    screenWidth > 780 && <Text>Orders</Text>
                }
            </HStack>

            <HStack height={"60px"}
                    width={"100%"}
                    paddingLeft={"27px"}
                    spacing={10} 
                    className="home-menu"
                    style={{backgroundColor: redeemSelected && "#eeeeee"}}
                    onClick={handleClickRedeem}
                    >
                <FontAwesomeIcon icon={faFile} color={redeemSelected ? "orange" : "gray"} size="lg" />
                {
                    screenWidth > 780 && <Text>Redeem</Text>
                }
            </HStack>

            <HStack height={"60px"}
                    width={"100%"}
                    paddingLeft={"27px"}
                    spacing={9} 
                    className="home-menu"
                    style={{backgroundColor: referSelected && "#eeeeee"}}
                    onClick={handleClickRefer}
                    >
                <FontAwesomeIcon icon={faUserFriends} color={referSelected ? "orange" : "gray"} size="sm" />
                {
                     screenWidth > 780 && <Text>Refer</Text>
                }
            </HStack>

        </VStack>

    );

}

export default UserLeftMenu;