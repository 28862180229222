import { HStack, Text, Box, VStack } from "@chakra-ui/react";
import "./UserContentHeader.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faSignOut } from "@fortawesome/free-solid-svg-icons";


const UserContentHeader = ({ children }) => {
    const username = localStorage.getItem("username");
    const [ isUserSetBtnClicked, setIsUserSetBtnClicked ] = useState(false);
    const nav = useNavigate();

    var defaultColor = '';

    if (localStorage.getItem('default-color') !== null) {
        defaultColor = localStorage.getItem('default-color');
    } else {
        defaultColor = '#0c4a96';
    }

    const handleLogout = () => {
        localStorage.removeItem("username");
        localStorage.removeItem("token");
        localStorage.removeItem("token_start_at");
        localStorage.removeItem("session_id");
        setIsUserSetBtnClicked(false)
        nav("/");
    }


    return (

        <HStack justifyContent={"start"}
                    width={"100%"}
                    // height={"170px"}
                    paddingTop={"20px"}
                    align={"start"} 
                    >
                {children}
                
                <HStack width={"20%"}
                        justifyContent={"end"}
                        height={"100%"}
                        align={"start"}
                        >
                    <VStack>
                        <button className="username-button" onClick={() => setIsUserSetBtnClicked(!isUserSetBtnClicked)}>
                            <HStack spacing={4}
                                    justifyContent={"center"}>
                                <Box width="35px"
                                    height="35px"
                                    borderRadius={"50%"}
                                    display={"flex"}
                                    backgroundColor={defaultColor} 
                                    justifyContent={"center"}
                                    alignItems={"center"} >
                                    <Text color={"white"}>H</Text>
                                </Box>
                                <Text fontSize={"sm"}>{username} </Text>
                                <Text fontSize={"sm"}>&#9660;</Text>
                            </HStack>
                        </button>
                        { isUserSetBtnClicked && 
                        <VStack width={"100%"}
                                    height={"70px"}
                                    backgroundColor={"white"}
                                    borderRadius={"10px"} 
                                    paddingLeft={"15px"}
                                    align={"start"} 
                                    justifyContent={"center"}
                                    >
                            <HStack className="user-setting-button" spacing={3} width={"100%"} >
                                <FontAwesomeIcon icon={ faGear } size="sm" />
                                <Text fontSize={"sm"}>Account Setting</Text>
                            </HStack>
                            <HStack className="user-setting-button" spacing={3} width={"100%"} onClick={handleLogout} >
                                <FontAwesomeIcon icon={ faSignOut } size="sm" />
                                <Text fontSize={"sm"}>Log out</Text>
                            </HStack>
                        </VStack> }
                    </VStack>
                </HStack>
                    
            </HStack>


    );

}

export default UserContentHeader;