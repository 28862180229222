import { createContext, useContext, useState } from "react";

const GlobalContext = createContext(undefined);

export const GlobalDataProvider = ({ children }) => {
    const [ username, setUserName ] = useState('');
    const [ token, setToken ] = useState('');
    const [ sessionId, setSessionId ] = useState('');

    return (
        <GlobalContext.Provider
            value={{username: username,
                   token: token,
                   sessionId: sessionId,
                //    baseUrl: "https://server.cnnexco.com:8443",
                    baseUrl: "https://cxvpn.heasor.com:8443",
                   setGlobalUserName: (un) => setUserName(un),
                   setGlobalToken: (t) => setToken(t),
                   setGlobalSessionId: (id) => setSessionId(id),
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
}

export const useData = () => useContext(GlobalContext);
