import { HStack, Text, Box, VStack, Button } from "@chakra-ui/react";
import "./UserHomeContent.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useData } from "../context/GlobalContext";
import useFetchList from "../hooks/useFetchList";
import useFetchMemberInfo from "../hooks/useFetchMemberInfo";
import UserContentHeader from "./UserContentHeader";
import MemberShipType from "./MembershipType";
import OtherProducts from "./OtherProducts";
import usePostWithoutBody from "../hooks/usePostWithoutBody";
import bcrypt from "bcryptjs-react";


const Logo = require("../logos/logo.ico");

const generatePlainId = () => {
    const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~";
    let randomString = "";
    for (let i = 0; i < 10; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        randomString += charset[randomIndex];
    }
    const plainSecretId = 'x*7x!@la~89_+ove%cX&for#2Ev08!@vRW6hYhIu';
    const salt = bcrypt.genSaltSync(11);
    const hash = bcrypt.hashSync(plainSecretId, salt);
    const currentMilliseconds = new Date().getTime();
    var shortHash = hash.slice(7) + currentMilliseconds + randomString;
    shortHash = shortHash.replaceAll("/", "backslash");
    return shortHash;
}

const UserHomeContent = () => {

    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const { baseUrl } = useData();
    const { result, submitWithoutBody } = usePostWithoutBody();
    const tempTokenUrl = baseUrl + "/api/verify_id/temp_access_token";
    const headerForTempToken = {
        "Content-Type" : "application/json",
        "id": generatePlainId(),
    };
    useEffect(() => {
        submitWithoutBody(tempTokenUrl, headerForTempToken);
    }, []);

    // console.log(result);

    const { data, submit } = useFetchList();
    const url = baseUrl + "/api/verify_access_token/get_event_content";
    const header = {
        "Content-Type" : "application/json",
        // "id" : localStorage.getItem("session_id"), 
        "id": result.tempToken,
    }

    useEffect(() => {
        // console.log(result);
        if (result !== undefined && result.cstatus === '003' ) {
            // console.log("Test");
            submit(url, header);
        }
    }, [result]);

    const { memberInfo, submitMemberInfo } = useFetchMemberInfo();
    const urlMemberInfo = baseUrl + "/api/verify_session/get_user_member_info";
    const headerMemberInfo = {
        "Content-Type" : "application/json",
        "tk": localStorage.getItem("token") ,
    }

    useEffect(() => {
        submitMemberInfo(urlMemberInfo, headerMemberInfo);
    }, []);

    // console.log(memberInfo);

    return (
        <div className="user-home-content-container">
            <UserContentHeader >
                <VStack width={"100%"}
                            height={"100%"}
                            align={"start"} >
                        <Text fontSize={ screenWidth > 780 ? "2xl" : "xl"} as={'b'}>Home</Text>
                        <VStack alignItems={"start"}
                                width={"100%"} >
                            <HStack spacing={5}>
                                <Text fontSize={ screenWidth > 780 ? "4xl": "3xl"} as={'b'}>Welcome</Text>
                                <FontAwesomeIcon color="orange" icon={faUserFriends} size="2x" />
                            </HStack>
                            <HStack width={"100%"} >
                                <Text fontSize={ screenWidth > 780 ? "md" : "sm"} >Downloads apps, set your account, manage your order and bills.</Text>
                            </HStack>
                        </VStack>
                </VStack>
            </UserContentHeader>

            <br />
            {data[0] !== undefined && data[0].status !== "error" && data.map((item) => (
                <Box width={"100%"}
                    backgroundColor={"white"}
                    borderRadius={"10px"}
                    display={"flex"}
                    alignItems={"center"}
                    key={item.id}
                    paddingBottom={"30px"}
                    marginBottom={"20px"}
                >
                    <VStack width={ screenWidth > 780 ? "70%" : "100%" } 
                            height={"100%"} 
                            align={"start"}
                            paddingLeft={"40px"}
                            paddingTop={"20px"}
                            spacing={4} >
                        <Text fontSize={ screenWidth > 780 ? "2xl" : "xl" } as={'b'} color={"#555555"}>{item.title}</Text>
                        {
                            screenWidth <= 780 &&
                            <HStack width={"30%"} height={"100%"} justifyContent={"end"} spacing={-3}>
                                <img src={item.img_url} width={"140px"} height={"140px"} style={{borderRadius: "50%"}} alt="flyer" />
                                <Box width={"20%"} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"end"} >
                            </Box>
                            </HStack>
                        }
                        <Text color={"#666666"} fontSize={ screenWidth > 780 ? "md" : "sm" }>{item.content.split("|")[0]}</Text>
                        <Text color={"#666666"} fontSize={ screenWidth > 780 ? "md" : "sm" }>{item.content.split("|")[1]}</Text>
                        <HStack spacing={10}>
                            <Button width={"150px"} 
                                    borderRadius={"20px"} colorScheme="red"
                                    fontSize={ screenWidth > 780 ? "md" : "sm" }
                                    size={ screenWidth > 780 ? 'sm' : 'xs'}
                                    >Buy Service</Button>
                        </HStack>
                        <Text color={"gray"} fontSize={ screenWidth > 780 ? "md" : "sm" }>{item.period}</Text>

                    </VStack>
                    {
                        screenWidth > 780  &&
                        <HStack width={"30%"} height={"100%"} justifyContent={"end"} spacing={-3}>
                            <img src={item.img_url} width={"140px"} height={"140px"} style={{borderRadius: "50%"}} alt="flyer" />
                            <Box width={"20%"} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"end"} >
                            </Box>
                        </HStack>
                    }
                </Box>
            ))}
            <br />
            <Text>Services</Text>
            <br />
            <Box width={"100%"}
                 backgroundColor={"white"}
                 borderRadius={"10px"}
                 display={"flex"}
                 flexDirection={"column"}
                 paddingBottom={"30px"}>
                <HStack paddingLeft={"40px"}
                        paddingTop={"20px"} >
                    <img src={Logo} width={"25px"} alt="logo" />
                    <Text fontSize={ screenWidth > 780 ? "xl" : "md"} as={'b'}>CNNEX VPN</Text>
                </HStack>
                { memberInfo.length > 0 && 
                <HStack paddingLeft={"40px"}
                        paddingTop={"20px"} 
                        justifyContent={"space-between"}
                        paddingRight={"50px"}>
                    <Text fontSize={ screenWidth > 780 ? "lg" : "sm" } as={'b'} color={'#555555'}> Your Member Type: 
                          {(memberInfo[0].member_type === 1 || memberInfo[0].member_type === null || memberInfo[0].status === 'error' ) && ' No Subscription'}
                          {memberInfo[0].member_type === 2 && ' Basic Member'} 
                          {memberInfo[0].member_type === 3 && ' Golden Member'}
                          {memberInfo[0].member_type === 4 && ' Platinum Member'}
                          {memberInfo[0].member_type === 5 && ' Exclusive Member'}
                    </Text>
                    {
                        screenWidth > 780 &&
                        <>
                            { (memberInfo[0].member_type === 1 || memberInfo[0].member_type === null || memberInfo[0].status === 'error' ) &&
                                <Button size={"sm"} borderRadius={"15px"} colorScheme="red" >Buy Service</Button>
                            }
                            { (memberInfo[0].member_type === 2 || memberInfo[0].member_type === 3 || memberInfo[0].member_type === 4) &&
                                <Button size={"sm"} borderRadius={"15px"} colorScheme="red" >Upgrade</Button>
                            }
                        </>
                    }
                </HStack>
                }
                { memberInfo.length > 0 && memberInfo[0].expired_on !== undefined &&
                <HStack paddingLeft={"40px"}
                        paddingTop={"20px"}>
                    <Text fontSize={screenWidth > 780 ? "lg" : "sm"} >Expired on: {memberInfo[0].expired_on}</Text>
                </HStack>
                }
                { memberInfo.length > 0 && memberInfo[0].favor_id !== undefined && 
                    <HStack paddingLeft={"40px"}
                            paddingTop={"20px"}>
                        <Text fontSize={screenWidth > 780 ? "lg" : "sm"}>Premium Event: Subscribed</Text>
                    </HStack>
                }
                { memberInfo.length > 0 && memberInfo[0].addon_id !== undefined && 
                    <HStack paddingLeft={"40px"}
                            paddingTop={"20px"}>
                        <Text fontSize={screenWidth > 780 ? "lg" : "sm"}>10+ Regions Services: Subscribed</Text>
                    </HStack>
                }
                {
                    screenWidth <= 780 &&
                    <HStack width={"100%"} marginTop={"15px"} paddingLeft={"40px"} >
                        { ( memberInfo[0] !== undefined && (memberInfo[0].member_type === 1 || memberInfo[0].member_type === null || memberInfo[0].status === 'error' )) &&
                            <Button size={"xs"} borderRadius={"15px"} colorScheme="red" width={"50%"} >Buy Service</Button>
                        }
                        { ( memberInfo[0] !== undefined && (memberInfo[0].member_type === 2 || memberInfo[0].member_type === 3 || memberInfo[0].member_type === 4)) &&
                            <Button size={"xs"} borderRadius={"15px"} colorScheme="red" width={"50%"} >Upgrade</Button>
                        }
                    </HStack>
                }
            </Box>
            <br />
            <Text>Memberships</Text>
            <br />
            <MemberShipType currentId={ (memberInfo[0] !== undefined && memberInfo[0].status !== 'error') ? memberInfo[0].member_type : 1 }/>
            <br />
            <Text>Other Services</Text>
            <br />
            <OtherProducts />
            <br />
            <br />
            { screenWidth > 780 ? 
            <HStack>
                <Text fontSize={"xs"}>© 2024 Nord Security. All rights reserved.  </Text>
                <button className="bottom-button"><Text fontSize={"xs"}>service@cnnexaccount.com</Text></button>
                <button className="bottom-button"><Text fontSize={"xs"}>Terms of service</Text></button>
                <button className="bottom-button"><Text fontSize={"xs"}>Privacy policy</Text></button>

            </HStack>
            :
            <VStack align={"start"} spacing={3} width={"100%"}>
                <button className="bottom-button"><Text fontSize={"xs"}>service@cnnexaccount.com</Text></button>
                
                <HStack width={"100%"} align={"start"} >
                    <button className="bottom-button"><Text fontSize={"xs"}>Terms of service</Text></button>
                    <button className="bottom-button"><Text fontSize={"xs"}>Privacy policy</Text></button>
                </HStack>

                <Text fontSize={"xs"} marginLeft={"10px"}>© 2024 Nord Security. All rights reserved.  </Text>
            </VStack>
            }
            <br />
            <br />
            <br />
        </div>
    );
}

export default UserHomeContent;