import React, { useEffect, useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import usePostWithBody from "../hooks/usePostWIthBody";
import { useData } from "../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import { useAlertContext } from "../context/AlertContext";


const GoogleSignIn = ({width, device, headId}) => {

    const { result, submitPost } = usePostWithBody();
    const { baseUrl } = useData();
    const nav = useNavigate();
    const [ usrName, setUsrName ] = useState("");
    const { onOpen } = useAlertContext();


    // console.log(result);

    useEffect(() => {
        if (result != null && device === 'web setting' && result.cstatus !== '000') {
            // console.log("Google Log in Test")
            localStorage.setItem('token', result.cst);
            localStorage.setItem('username', usrName);
            localStorage.setItem('session_id', headId);
            const tokenGenTime = new Date().getTime();
            localStorage.setItem('token_start_at', tokenGenTime);   
            nav('/dashboard');
        }
    }, [result, device, headId, usrName, nav]);

    return (
        <div style={{width: "100%"}}>
            <GoogleLogin
                onSuccess={(credentialResponse) => {
                    if ( new Date().getTime() - parseInt(headId.slice(headId.length - 23, headId.length - 10)) > 2 * 60 * 1000 ) {
                        onOpen("fail", "Your link was expired, please sign up again");
                        nav("/");
                    }
                    
                    const credentialResponseDecoded = jwtDecode(credentialResponse.credential);
                    setUsrName(credentialResponseDecoded.email);
                    const header = {
                        "Content-Type": "application/json",
                        // "id": generateId(),
                        "id": localStorage.getItem("temp_access_token"),
                    };
                    const bodyData = {
                        "userName": credentialResponseDecoded.email,
                        "deviceInfo": device,
                    };                   
                    submitPost(baseUrl + "/api/verify_access_token/google_sign_in", header, bodyData);

                    // console.log(credentialResponseDecoded);
                }}
                onError={() => console.log("Login Failed")}
                width={width}
            />
        </div>

    );
}

export default GoogleSignIn;