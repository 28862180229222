import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import HomePage from './pages/HomePage';
import { Routes, Route } from 'react-router-dom'
import LoginPage from './pages/LoginPage';
import NotFound from './components/NotFound';
import { GlobalDataProvider } from './context/GlobalContext';
import UserHomePage from './pages/UserHomePage';
import { AlertProvider } from './context/AlertContext';
import CusAlert from './components/CusAlert';
import SignupPage from './pages/SignupPage';

function App() {
  return (
    <ChakraProvider>
      <GlobalDataProvider>
        <AlertProvider>
          <CusAlert /> 
          <Routes>
            <Route path='/' element={<HomePage />}></Route>
            <Route path='/login/:id' element={<LoginPage />}></Route>
            <Route path='/dashboard' element={<UserHomePage />}></Route>
            <Route path='/signup/:id' element={<SignupPage />}></Route>
            <Route path='*' element={<NotFound />}></Route>
          </Routes>
        </AlertProvider>
      </GlobalDataProvider>
    </ChakraProvider>

  );
}

export default App;
