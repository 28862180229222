import UserContentHeader from "./UserContentHeader";
import "./UserDownloadContent.css";
import { HStack, Text, VStack, Box, Button } from "@chakra-ui/react";
import { useState, useEffect } from "react";


const windowsPc = require("../images/windows-pc.jpg")
const macOS = require("../images/macOS.jpg");
const androidPhone = require("../images/android-phone.jpg");
const iphone = require("../images/iphone.jpg");
const grayLogo = require("../logos/logo-gray.png");

const UserDownloadContent = () => {

    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const apps = [
        {
            id: 1,
            name: 'CnnexVPN for Windows',
            version: 'Windows 10 & 11',
            pic: windowsPc,
        },
        {
            id: 2,
            name: 'CnnexVPN for macOS',
            version: 'Version 11 and up',
            pic: macOS,
        },
        {
            id: 3,
            name: 'CnnexVPN for Android',
            version: 'Version 13.0 and up',
            pic: androidPhone,
        }, 
        {
            id: 4, 
            name: 'CnnexVPN for iPhone',
            version: 'iOS 15 and up',
            pic: iphone,
        }
    ];

    return (
        <div className="user-download-container">
            <UserContentHeader >
                <VStack width={"80%"}
                        height={"100%"}
                        align={"start"} >
                    <Text fontSize={"2xl"} as={'b'}>Downloads</Text>
                </VStack>
            </UserContentHeader>
            <br />
            <Text>CnnexVPN Apps</Text>
            <br />

            <Box display={'grid'}
                 gradgap={2}
                 gridTemplateColumns={'repeat(2, minmax(0, 1fr))'}
                 css={{
                    '@media (max-width: 680px)' : {
                        gridTemplateColumns: '1fr',
                    },
                 }}
                 marginBottom={"30px"} >
                {apps.map((app) => (
                    <HStack width={"90%"} key={app.id}>
                        <Box 
                            width={"100%"}
                            height={"500px"}
                            backgroundColor={"white"}
                            borderRadius={"10px"}
                            // padding={"25px"} 
                            marginBottom={"30px"}>
                            <Box width={"100%"}
                                    height={"62%"}
                                    backgroundColor={"#eeeeee"}
                                    borderRadius={"10px"} 
                                    marginBottom={"10px"}
                                    className="product-pic-bk" 
                                    style={{ backgroundImage : `url(${app.pic})`}}>
                                
                            </Box>
                            <HStack width={"100%"}
                                    marginLeft={"10px"}
                                    padding="10px">
                                <img src={grayLogo} width="20px" alt={app.id} />
                                <Text>High-speed VPN</Text>
                            </HStack>
                            <Text padding={"10px"} fontSize={"xl"} as={'b'} marginLeft={"10px"}>{app.name}</Text>
                            <Text padding={"10px"} fontSize={"sm"} marginLeft={"10px"}>{app.version}</Text>
                            <Button padding={"10px"} marginLeft={"20px"} marginTop={"10px"}
                                    width={"90%"} height={"35px"} colorScheme='telegram'>Download App</Button>
                        </Box>
                    </HStack>
                ))}
            </Box>
            { screenWidth > 780 ? 
            <HStack>
                <Text fontSize={"xs"}>© 2024 Nord Security. All rights reserved.  </Text>
                <button className="bottom-button"><Text fontSize={"xs"}>service@cnnexaccount.com</Text></button>
                <button className="bottom-button"><Text fontSize={"xs"}>Terms of service</Text></button>
                <button className="bottom-button"><Text fontSize={"xs"}>Privacy policy</Text></button>

            </HStack>
            :
            <VStack align={"start"} spacing={3} width={"100%"}>
                <button className="bottom-button"><Text fontSize={"xs"}>service@cnnexaccount.com</Text></button>
                
                <HStack width={"100%"} align={"start"} >
                    <button className="bottom-button"><Text fontSize={"xs"}>Terms of service</Text></button>
                    <button className="bottom-button"><Text fontSize={"xs"}>Privacy policy</Text></button>
                </HStack>

                <Text fontSize={"xs"} marginLeft={"10px"}>© 2024 Nord Security. All rights reserved.  </Text>
            </VStack>
            }
            <br />
            <br />
            <br />

        </div>
    );
};

export default UserDownloadContent;