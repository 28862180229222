import { VStack, Text, HStack } from "@chakra-ui/react";
import { FontAwesomeIcon }  from "@fortawesome/react-fontawesome";
import { faHouse, faDownload, faGears, faFile, faUserFriends, faReceipt } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const blackLogo = require("../logos/logo-black.png");

const UserTopMenuMobile = ({ handleClickedMenu }) => {

    const [ homeSelected, setHomeSelected ] = useState(true);
    const [ downloadSelected, setDownloadSelected ] = useState(false);
    const [ consoleSelected, setConsoleSelected ] = useState(false);
    const [ ordersSelected, setOrderSelected ] = useState(false);
    const [ redeemSelected, setRedeemSelected ] = useState(false);
    const [ referSelected, setReferSelected ] = useState(false);


    const handleClickHome = () => {
        handleClickedMenu(1);
        setHomeSelected(true);
        setDownloadSelected(false);
        setConsoleSelected(false);
        setOrderSelected(false);
        setRedeemSelected(false);
        setReferSelected(false)
    }

    const handleClickDownload = () => {
        handleClickedMenu(2);
        setDownloadSelected(true);
        setHomeSelected(false);
        setConsoleSelected(false);
        setOrderSelected(false);
        setRedeemSelected(false);
        setReferSelected(false)
    }

    const handleClickConsole = () => {
        handleClickedMenu(3);
        setConsoleSelected(true);
        setDownloadSelected(false);
        setHomeSelected(false);
        setOrderSelected(false);
        setRedeemSelected(false);
        setReferSelected(false)
    }

    const handleClickOrders = () => {
        handleClickedMenu(4);
        setOrderSelected(true);
        setHomeSelected(false);
        setDownloadSelected(false);
        setConsoleSelected(false);
        setRedeemSelected(false);
        setReferSelected(false)
    }

    const handleClickRedeem = () => {
        handleClickedMenu(5);
        setRedeemSelected(true);
        setOrderSelected(false);
        setHomeSelected(false);
        setDownloadSelected(false);
        setConsoleSelected(false);
        setReferSelected(false)
    }

    const handleClickRefer = () => {
        handleClickedMenu(6);
        setReferSelected(true)
        setRedeemSelected(false);
        setOrderSelected(false);
        setHomeSelected(false);
        setDownloadSelected(false);
        setConsoleSelected(false);
    }

    return (
        <VStack width={"100%"} spacing={5}>

            <HStack marginTop={"20px"}
                    width={"100%"}
                    paddingLeft={"25px"} 
                    >

                <img src={blackLogo} width={"36px"} alt="blacklog" />
                
                <HStack>
                    <Text fontSize={"xl"} as={'b'} color={"#797979"}>CNNEX</Text>
                    <Text fontSize={"xl"} color={"gray"}>Account</Text>
                </HStack>
                
            </HStack>

            <HStack width={ "100%" } height={"10%"} paddingTop={"20px"} spacing={5} paddingLeft={"35px"} paddingBottom={"10px"}>
                <FontAwesomeIcon icon={faHouse} color={homeSelected ? "orange" : "gray"} size="lg" onClick={handleClickHome} />
                <FontAwesomeIcon icon={faDownload} color={downloadSelected ? "orange" : "gray"} size="lg" onClick={handleClickDownload} />
                <FontAwesomeIcon icon={faGears} color={consoleSelected ? "orange" : "gray"} size="sm" onClick={handleClickConsole} />
                <FontAwesomeIcon icon={faReceipt} color={ordersSelected ? "orange" : "gray"} size="lg" onClick={handleClickOrders} />
                <FontAwesomeIcon icon={faFile} color={redeemSelected ? "orange" : "gray"} size="lg" onClick={handleClickRedeem} />
                <FontAwesomeIcon icon={faUserFriends} color={referSelected ? "orange" : "gray"} size="sm" onClick={handleClickRefer} />
            </HStack>
        </VStack>
    )

}

export default UserTopMenuMobile;