import { VStack } from "@chakra-ui/react";
import UserHomeContent from "./UserHomeContent";
import UserDownloadContent from "./UserDownloadContent";
import UserSettingConsoleContent from "./UserSettingConsoleContent";
import { useState, useEffect } from "react";


const UserMenuContentContainer = ({item}) => {

    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <VStack width={ screenWidth > 780 ? "83%" : "100%"}
            height={"100%"}
            background={"#eeeeee"}>
            {/* {item === 1 && <Text>Home Content</Text>} */}
            {(item === 1 || item === 0) && <UserHomeContent />}
            {(item === 2) && <UserDownloadContent />}
            {(item === 3) && <UserSettingConsoleContent />}
        </VStack>
    );
}

export default UserMenuContentContainer;