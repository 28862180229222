import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import { useState, useEffect } from "react";

const passLogo = require('../logos/logo-pass.png');
const noteLogo = require('../logos/logo-note.png');

const OtherProducts = () => {

    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const products = [
        {
            id: 1,
            icon: passLogo,
            pname: 'CnnexPass',
            intro: 'Safely store your passwords, and keep your files secure in encrypted format',
            color: '#513a7a',
        }, 
        {
            id: 2,
            icon: noteLogo,
            pname: 'CnnexNote',
            intro: 'Store your important notes securely and access them anytime, anywhere',
            color: '#3f4a3d',
        }   
    ];

    return (
        <div style={{width: "100%"}} >
            {products.map((product) => (
                <VStack key={product.id} 
                        width={"100%"} 
                        backgroundColor={"white"} 
                        marginBottom={"20px"} 
                        borderRadius={"10px"}
                        // paddingLeft={"40px"}
                        paddingRight={"20px"}
                        paddingTop={"15px"}
                        paddingBottom={"15px"}
                        align={"start"} 
                        spacing={3}>
                    <HStack spacing={4}
                            marginLeft={"40px"}>
                        <img src={product.icon} width={"30px"} alt="logo" />
                        <Text fontSize={'lg'} as={'b'}>{product.pname}</Text>
                    </HStack>

                { screenWidth > 780 ?
                    <HStack width={"100%"}
                            justifyContent={"space-between"}
                            paddingRight={"30px"} 
                            backgroundColor={"#eeeeee"} 
                            marginLeft={"15px"}
                            paddingLeft={"25px"}
                            paddingTop={"10px"} 
                            paddingBottom={"10px"} 
                            borderRadius={"10px"} >
                        <Text>{product.intro}</Text>
                        <HStack spacing={5}>
                            <Button backgroundColor={"#eeeeee"}
                                    border={"1px solid gray"}
                                    borderRadius={"20px"}
                                    color={product.color}>
                                <Text fontSize={"sm"}>Get Free Version</Text>
                            </Button>
                            <Button backgroundColor={product.color}
                                    border={"1px solid gray"}
                                    borderRadius={"20px"}
                                    color={"white"} >
                                <Text fontSize={"sm"}>Get Premium</Text>
                            </Button>
                        </HStack>
                    </HStack> :
                    <VStack width={"100%"}
                            // justifyContent={"space-between"}
                            paddingRight={"30px"} 
                            backgroundColor={"#eeeeee"} 
                            marginLeft={"15px"}
                            paddingLeft={"25px"}
                            paddingTop={"10px"} 
                            paddingBottom={"10px"} 
                            borderRadius={"10px"} 
                            spacing={5}>
                        <Text>{product.intro}</Text>
                        <VStack spacing={5} width={"100%"} align={"start"} >
                            <Button backgroundColor={"#eeeeee"}
                                    border={"1px solid gray"}
                                    borderRadius={"20px"}
                                    color={product.color} 
                                    width={"150px"} >
                                <Text fontSize={"sm"}>Get Free Version</Text>
                            </Button>
                            <Button backgroundColor={product.color}
                                    border={"1px solid gray"}
                                    borderRadius={"20px"}
                                    width={"150px"}
                                    color={"white"} >
                                <Text fontSize={"sm"}>Get Premium</Text>
                            </Button>
                        </VStack>
                    </VStack>
                }    

                </VStack>
            ))}
        </div>
    );
}

export default OtherProducts;