import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const AlertContext = createContext(undefined);

export const AlertProvider = ({ children }) => {
    const nav = useNavigate();

    const [ state, setState ] = useState({
        isOpen: false,
        type: "success",
        message: "",
    });

    return (
        <AlertContext.Provider
            value = {{
                ...state,
                onOpen: (type, message) => setState({isOpen: true, type, message}), 
                onClose: () => {
                    if (state.message === "Your sesssion has expired, please log in again.") {
                        localStorage.removeItem("token");
                        localStorage.removeItem("username");
                        localStorage.removeItem("token_start_at");
                        localStorage.removeItem("session_id");
                        nav("/");
                    }

                    setState({isOpen: false, type: '', message: ''}); 
                    
                } 
            }}
        >
            { children }
        </AlertContext.Provider>

    );
};

export const useAlertContext = () => useContext(AlertContext);