import { Box, HStack, Text, Input, VStack, Button } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";

import "../App.css";
import { useEffect, useState } from "react";
import bcrypt from "bcryptjs-react";
import usePostWithBody from "../hooks/usePostWIthBody";
import { useData } from "../context/GlobalContext";
import { useAlertContext } from "../context/AlertContext";
import GoogleSignInComponent from "../components/GoogleSignInComponent";


const Logo = require("../logos/logo-white.png")
// Regular expression to match non-letter characters
const nonLetterRegex = /^[^a-zA-Z]/;

const generateRandomString = (len) => {
    const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~";
    let randomString = "";
    for (let i = 0; i < len; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        randomString += charset[randomIndex];
    }
    return randomString;
}


const SignupPage = () => {

    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [ usrname, setUsrName ] = useState('');
    const [ pwd, setPwd ] = useState('');
    const [ doublePwd, setDoublePwd ] = useState('');
    const [ isUserNameTouched, setUserNameTouched ] = useState(false);
    const [ isPwdTouched, setIsPwdTouched ] = useState(false); 
    const [ isConfirmPwdTouched, setIsConfirmPwdTouched ] = useState(false);
    const [ usernameErrMsg, setUsernameErrMsg ] = useState("");
    const [ pwdErrorMsg, setPwdErrorMsg ] = useState("");
    const [ confirmPwdErrMsg, setConfirmPwdErrMsg ] = useState("");
    const [ submitErr, setSubmitErrMsg ] = useState("");

    const { id } = useParams();
    const nav = useNavigate();
    const { baseUrl } = useData();
    const { isLoading, result, submitPost } = usePostWithBody();
    const { onOpen } = useAlertContext();

    const plainSecretId = process.env.REACT_APP_PLAIN_SECRET_ID;
    // console.log(localStorage.getItem("temp_access_token"));


    const handleUserInputChange = (e) => {
        e.preventDefault();
        setUsrName(e.target.value);
        // setUserNameTouched(false);
        setSubmitErrMsg("");
    }

    const handlePwdChange = (e) => {
        e.preventDefault();
        setPwd(e.target.value);
        setSubmitErrMsg("");
    }

    const handleUsrBlur = () => {
        setUserNameTouched(true);
    }

    const hanldePwdBlur = () => {
        setIsPwdTouched(true);
    }

    const handlecConfirmPwdChange = (e) => {
        e.preventDefault();
        setDoublePwd(e.target.value);
        setSubmitErrMsg("");
    }

    const handleConfrimPwdBlur = () => {
        setIsConfirmPwdTouched(true);
    }

    const handleSubmit = () => {
        const encodedPassword = btoa(pwd);
        const header = {
            "Content-Type": "application/json",
            // "id": id,
            "id": localStorage.getItem("temp_access_token"),
        }
        const bodyDate = {
            "userName": usrname,
            "password": encodedPassword,
            "email": "",
            "phone": "",
            "memberType": 1,
        }
        const url = baseUrl + "/api/verify_access_token/safe_signup";
        submitPost(url, header, bodyDate);
        // setSunmitErrMsg("The username was token already.");
    }

    // console.log(result);
    useEffect(() => {
        // console.log(result);
        if (result != null ) {
            if (result.cstatus === '002') {
                setUsrName("");
                setPwd("");
                setDoublePwd("");
                setUserNameTouched(false);
                setIsPwdTouched(false);
                setIsConfirmPwdTouched(false);
                setSubmitErrMsg("The username had been taken, please change one.")
            } else if (result.cstatus === '010') {
                onOpen("fail", "Your link was expired, please sign up again");
                nav("/");
            } else if (result.cstatus === '000') {
                setUsrName("");
                setPwd("");
                setDoublePwd("");
                setUserNameTouched(false);
                setIsPwdTouched(false);
                setIsConfirmPwdTouched(false);
                setSubmitErrMsg("Network error, please try it later.")
            } else {
                const salt = bcrypt.genSaltSync(11);
                const hash = bcrypt.hashSync(plainSecretId, salt);
                const currentMilliseconds = new Date().getTime();
                var shortHash = hash.slice(7) + currentMilliseconds + generateRandomString(10);
                shortHash = shortHash.replaceAll("/", "backslash");
                nav(`/login/${shortHash}`)
                onOpen("success", "Register new account successfully.")
            }
        }
        // eslint-disable-next-line
    }, [result])

    useEffect(() => {

        if (isPwdTouched && pwd.length < 8) {
            setPwdErrorMsg("Password must has more than 8 characters.");
        } else {
            setPwdErrorMsg("");
        };

        if (isUserNameTouched && usrname.length < 5) {
            setUsernameErrMsg("Username must has more than 5 characters.")
        };

        if (isUserNameTouched && nonLetterRegex.test(usrname) ) {
            setUsernameErrMsg("Username must start with a letter.")
        } 

        if (isUserNameTouched && usrname.length >= 5 && !nonLetterRegex.test(usrname)) {
            setUsernameErrMsg("");
        }

        if (pwd !== doublePwd) {
            setConfirmPwdErrMsg("Your input passwords are inconsistent.")
        } else {
            setConfirmPwdErrMsg("");
        }

    }, [pwd, isPwdTouched, usrname, isUserNameTouched, doublePwd, isConfirmPwdTouched]);

    useEffect(() => {
        if (!bcrypt.compareSync(plainSecretId, "$2a$11$" + id.slice(0, id.length - 23).replaceAll("backslash", "/"))) {
            // if the id is incorrect, go to error page
            nav("/signup");
        }
    }, [id, nav, plainSecretId])


    return (
        <div className="login-container">
            <Text marginTop="5px" fontSize={"xs"} color={"gray"}>* This link is valid for 1 minute</Text>
            <div style={{width: "100%", height: "90%", display: "flex", justifyContent: "center"}} >
                <Box width={"450px"}
                        height={"600px"}
                        borderRadius={"10px"}
                        display={"flex"}
                        flexDirection={"column"}
                        marginTop={"70px"}
                        backgroundColor={"#232233"} >
                    <Box height={"17%"}
                         width={"100%"}
                         display={"flex"}
                         justifyContent={"center"}
                         alignItems={"center"} >
                        <div className="login-logo">
                            <img src={Logo} width={"30px"} alt="logo" onClick={() => nav("/")} />
                        </div>
                        <Text color={"white"} fontSize={"xl"} fontFamily={"Arial"}>&nbsp;&nbsp;</Text>
                        <Text color={"white"} as={'b'} fontSize={"2xl"} fontFamily={"Arial"}>CNNEX</Text>
                        <Text color={"white"} fontSize={"xl"} fontFamily={"Arial"}>&nbsp;&nbsp;</Text>
                        <Text color={"white"} fontSize={"xl"} fontFamily={"Arial"}>Account</Text>
                    </Box>
                    <Box height={"83%"}
                         width={"450px"}
                         display={"flex"}
                         flexDirection={"column"}
                         alignItems={"center"}>
                        <Text fontSize={"2xl"} color={"white"}>Sign Up</Text>
                        <br />
                        <Input width={"80%"} color={"gray"} placeholder="username" value={usrname} onChange={handleUserInputChange} 
                               onBlur={handleUsrBlur} 
                            //    disabled={isLoading} 
                        />
                        <div style={{display: "flex", alignContent: "start", width: "80%" }}>
                            <Text fontSize={"sm"} color={"red"}>{usernameErrMsg}</Text>
                        </div>
                        <br />
                        <Input width={"80%"} color={"gray"} placeholder="password" value={pwd} type="password" onChange={handlePwdChange}
                               onBlur={hanldePwdBlur}
                        />
                        <div style={{display: "flex", alignContent: "start", width: "80%" }}>
                            <Text fontSize={"sm"} color={"red"}>{pwdErrorMsg}</Text>
                        </div>
                        <br />
                        <Input width={"80%"} color={"gray"} placeholder="confirm password" value={doublePwd} type="password" onChange={handlecConfirmPwdChange}
                               onBlur={handleConfrimPwdBlur}
                        />
                        <div style={{display: "flex", alignContent: "start", width: "80%" }}>
                            <Text fontSize={"sm"} color={"red"}>{confirmPwdErrMsg}</Text>
                        </div>
                        <br />
                        <VStack spacing={1} 
                                width={"80%"}
                                align={"start"}>
                            <Button width={"100%"} height={"37px"} colorScheme="telegram" className="signup-btn"
                                    isDisabled={usernameErrMsg !== "" || pwdErrorMsg !== "" || confirmPwdErrMsg !== "" || usrname === "" || pwd === "" || doublePwd === ""} 
                                    onClick={handleSubmit} isLoading={isLoading}
                            >Submit</Button>
                            <Text fontSize={"sm"} color={"red"}>{submitErr}</Text>
                            <br />
                            <GoogleSignInComponent source="web setting"
                                           headId={id}/>
                        </VStack>
                    </Box>

                </Box>
            </div>

            {
                screenWidth > 780 ? 
                <div style={{width: "90%", height: "10%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <HStack spacing={5}>
                    <Text color={"gray"} fontSize={"sm"}>© 2024 Cnnex Limited Company. All rights reserved.</Text>
                    <Text color={"lightgray"} fontSize={"sm"}>service@cnnexco.com</Text>
                    <Text color={"lightgray"} fontSize={"sm"}>Terms of service</Text>
                </HStack>
                <HStack>
                    <button className="switch-button"
                            style={{color: "lightgray"}}><Text fontSize={"sm"}>switch to light mode</Text>
                    </button>
                </HStack>
                </div> :
                <VStack width={"90%"} align={"start"}>
                    <HStack width={"100%"}>
                        <Text color={"lightgray"} fontSize={"sm"}>service@cnnexco.com</Text>
                        <Text color={"lightgray"} fontSize={"sm"}>Terms of service</Text>
                    </HStack>
                    {/* <button className="switch-button"
                            style={{color: "lightgray"}}><Text fontSize={"sm"}>switch to light mode</Text>
                    </button> */}
                    <Text color={"gray"} fontSize={"sm"}>© 2024 Cnnex Limited Company. All rights reserved.</Text>
                </VStack>

            }

        </div>
    );
}

export default SignupPage;