import { HStack, Text, VStack, Box, Button } from "@chakra-ui/react";
import "./MembershipType.css";
import { useState, useEffect } from "react";

const WhiteLogo = require("../logos/logo-white.png");

const MemberShipType = ({ currentId }) => {

    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const memberTypes = [
        {
            id: 2,
            typeName: 'Basic Member',
            devices: 3,
            allSites: 19.99,
            traffic: 'Unlimited',
            usersPerChannel: 50,
            serviceLevel: '8 hours / 5 days per week',
            color: "skyblue",
        },
        {
            id: 3,
            typeName: 'Golden Member',
            devices: 4,
            allSites: 19.99,
            traffic: 'Unlimited',
            usersPerChannel: 25,
            serviceLevel: '8 hours / 5 days per week',
            color: "#FFD700",
        },
        {
            id: 4,
            typeName: 'Platinum Member',
            devices: 5,
            allSites: 19.99,
            traffic: 'Unlimited',
            usersPerChannel: 10,
            serviceLevel: '8 hours / 5 days per week',
            color: "#E0E0E0",
        },
        {
            id: 5,
            typeName: 'Exclusive Member',
            devices: 10,
            allSites: 0,
            traffic: 'Unlimited',
            usersPerChannel: 1,
            serviceLevel: '24 hours / 7 days per week',
            color: "tomato"
        }
    ];

    return (
        <div className="member-type-container">
            { memberTypes.map((mType) => (
                <div key={mType.id}>
                    {mType.id === 3 &&
                        <div  style={{marginBottom: "5px"}}>
                            <Text color={"tomato"} as={'b'} >Most Popular</Text>
                        </div>
                    }
                    { screenWidth > 780 ?
                    <HStack width={"100%"} backgroundColor={"white"} marginBottom={"20px"} borderRadius={"10px"} 
                            borderBottom={currentId === mType.id && "5px solid blue"}
                            borderTop={mType.id === 3 && "10px solid tomato"} >
                        <VStack marginBottom={"25px"}
                                paddingLeft={"40px"}
                                paddingRight={"50px"}
                                // backgroundColor={"white"}
                                width={"60%"}
                                paddingTop={"15px"}
                                paddingBottom={"15px"}
                                align={"start"} >
                            <Text fontSize={'xl'} as={'b'}>Member type: {mType.typeName} </Text>
                            <Text fontSize={'sm'}>Allowed devices: {mType.devices} United States Sites & {mType.devices} Chinese Sites</Text>     
                            <Text fontSize={'sm'}>Extra cost for unlocking 10+ regions sites: ${mType.allSites}</Text>
                            <Text fontSize={'sm'}>Allowed traffic: {mType.traffic}</Text>       
                            <Text fontSize={'sm'}>Users to share channel(more means less speed): {mType.usersPerChannel}</Text>
                            <Text fontSize={'sm'}>Service Lever: {mType.serviceLevel}</Text>
                        </VStack>
                        <VStack width={"40%"}
                                backgroundColor={"white"}
                                align={"end"}
                                paddingRight={"70px"}>
                            <Box width={"150px"}
                                height={"150px"}
                                borderRadius={"50%"}
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                backgroundColor={mType.color}>
                                <img src={WhiteLogo} width="60px" alt="logo" />
                                {mType.id > currentId &&
                                    <Button size={'sm'} backgroundColor={mType.color} color={'white'} borderRadius={"20px"} >
                                        {currentId !== 1 ? 'Upgrade' : 'Buy Service'}
                                    </Button>
                                }
                            </Box>
                                
                            {/* <Text>Get Service</Text> */}
                        </VStack>
                </HStack> :
                <VStack width={"100%"} backgroundColor={"white"} marginBottom={"20px"} borderRadius={"10px"} 
                        borderBottom={currentId === mType.id && "5px solid blue"}
                        borderTop={mType.id === 3 && "10px solid tomato"}>
                        <VStack marginBottom={"25px"}
                                paddingLeft={"40px"}
                                paddingRight={"50px"}
                                // backgroundColor={"white"}
                                width={"100%"}
                                paddingTop={"15px"}
                                paddingBottom={"15px"}
                                align={"start"} >
                            <Text fontSize={'xl'} as={'b'}>Member type: {mType.typeName} </Text>
                            <Text fontSize={'xs'}>Allowed devices: {mType.devices} United States Sites & {mType.devices} Chinese Sites</Text>     
                            <Text fontSize={'xs'}>Extra cost for unlocking 10+ regions sites: ${mType.allSites}</Text>
                            <Text fontSize={'xs'}>Allowed traffic: {mType.traffic}</Text>       
                            <Text fontSize={'xs'}>Users to share channel(more means less speed): {mType.usersPerChannel}</Text>
                            <Text fontSize={'xs'}>Service Lever: {mType.serviceLevel}</Text>
                            {mType.id > currentId &&
                                    <Button size={'sm'} backgroundColor={mType.color} color={'white'} borderRadius={"20px"} >
                                        {currentId !== 1 ? 'Upgrade' : 'Buy Service'}
                                    </Button>
                            }
                        </VStack>
                </VStack>
                }
                </div>
            )) }
        </div>
    );

}

export default MemberShipType;