import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import "../App.css";
import LoginComb from "../components/LoginComb";
import { useNavigate, useParams } from "react-router-dom";
import bcrypt from "bcryptjs-react";
import { useEffect, useState } from "react";


const Logo = require("../logos/logo-white.png")

const LoginPage = () => {

    const nav = useNavigate();
    const { id } = useParams();
    const plainSecretId = process.env.REACT_APP_PLAIN_SECRET_ID;

    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!bcrypt.compareSync(plainSecretId, "$2a$11$" + id.slice(0, id.length - 23).replaceAll("backslash", "/"))) {
            // if the id is incorrect, go to error page
            nav("/login");
        }
    }, [id, nav, plainSecretId])

    return (
        
        <div className="login-container">
            <Text marginTop="5px" fontSize={"xs"} color={"gray"}>* This link is valid for 1 minute</Text>

            <div style={{width: "100%", height: "90%", display: "flex", justifyContent: "center"}} >
                <Box width={"450px"}
                    height={"600px"}
                    borderRadius={"10px"}
                    display={"flex"}
                    flexDirection={"column"}
                    marginTop={"70px"}
                    backgroundColor={"#232233"} >
                    <Box height={"17%"}
                         width={"100%"}
                         display={"flex"}
                         justifyContent={"center"}
                         alignItems={"center"} >
                        <div className="login-logo">
                            <img src={Logo} width={"30px"} alt="logo" onClick={() => nav("/")} />
                        </div>
                        <Text color={"white"} fontSize={"xl"} fontFamily={"Arial"}>&nbsp;&nbsp;</Text>
                        <Text color={"white"} as={'b'} fontSize={"2xl"} fontFamily={"Arial"}>CNNEX</Text>
                        <Text color={"white"} fontSize={"xl"} fontFamily={"Arial"}>&nbsp;&nbsp;</Text>
                        <Text color={"white"} fontSize={"xl"} fontFamily={"Arial"}>Account</Text>
                    </Box>
                    <Box height={"83%"}
                         width={"450px"} >
                        <LoginComb headId={id}/>
                    </Box>

                </Box>
            </div>

            {
                screenWidth > 780 ? 
                <div style={{width: "90%", height: "10%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <HStack spacing={5}>
                    <Text color={"gray"} fontSize={"sm"}>© 2024 Cnnex Limited Company. All rights reserved.</Text>
                    <Text color={"lightgray"} fontSize={"sm"}>service@cnnexco.com</Text>
                    <Text color={"lightgray"} fontSize={"sm"}>Terms of service</Text>
                </HStack>
                <HStack>
                    <button className="switch-button"
                            style={{color: "lightgray"}}><Text fontSize={"sm"}>switch to light mode</Text>
                    </button>
                </HStack>
                </div> :
                <VStack width={"90%"} align={"start"}>
                    <HStack width={"100%"}>
                        <Text color={"lightgray"} fontSize={"sm"}>service@cnnexco.com</Text>
                        <Text color={"lightgray"} fontSize={"sm"}>Terms of service</Text>
                    </HStack>
                    {/* <button className="switch-button"
                            style={{color: "lightgray"}}><Text fontSize={"sm"}>switch to light mode</Text>
                    </button> */}
                    <Text color={"gray"} fontSize={"sm"}>© 2024 Cnnex Limited Company. All rights reserved.</Text>
                </VStack>

            }


        </div>
    );
}

export default LoginPage;