import { useState } from "react"

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms))


const useFetchMemberInfo = () => {
    const [ memberInfo, setMemberInfo ] = useState([]);

    const submitMemberInfo = async (url, header) => {
        const noBodyOption = {
            method: "POST",
            headers: header,
        }
    
        await wait(500);

        fetch(url, noBodyOption)
            .then(
                response => {
                    if (response.status !== 401) {
                        return response.json();
                    } else {
                        throw new Error("unauthorized");
                    }
                }
            ).then(
                responseData => {
                    setMemberInfo(responseData);
                }
            ).catch( 
                error => {
                    setMemberInfo([{
                        status: "error",
                        message: "unauthorized" + error.Error,
                    }]);
                }
            );
    };
    return {memberInfo, submitMemberInfo};
}

export default useFetchMemberInfo;