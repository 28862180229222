import { useNavigate } from "react-router-dom";
import "../App.css";
import bcrypt from "bcryptjs-react";
import { useEffect, useState } from "react";
import UserLeftMenu from "../components/UserLeftMenu";
import UserMenuContentContainer from "../components/UserMenuContentContainer";
import { useAlertContext } from "../context/AlertContext";
import UserTopMenuMobile from "../components/UserTopMenuMobile";
import { VStack } from "@chakra-ui/react";

var idleStartAt = 0;

const generateRandomString = (len) => {
    const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!$^*()_+-";
    let randomString = "";
    for (let i = 0; i < len; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        randomString += charset[randomIndex];
    }
    return randomString;
}

const getRaundomNum = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const colors = ['#0842a0', '#474747', '#20467e', '#384760', '#354a4d', '#005249', '#085112', '#3c4a3c', '#574500', '#7a3300', 
'#5b4134', '#7f2a41', '#5b4044', '#712f67', '#55397e' ]

const plainSecretId = process.env.REACT_APP_PLAIN_SECRET_ID;

const UserHomePage = () => {

    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (localStorage.getItem("default-color") === null) {
        const userColor = colors[getRaundomNum(1, colors.length) - 1];   
        localStorage.setItem("default-color", userColor)
    } 
                 
    const nav = useNavigate();
    
    const { onOpen } = useAlertContext();
    const [ isActive, setIsActive ] = useState(true);
    const [ menuItem, setMenuItem ] = useState(0);

    // const plainSecretId = 'x*7x!@la~89_+ove%cX&for#2Ev08!@vRW6hYhIu';
    const salt = bcrypt.genSaltSync(11);
    const hash = bcrypt.hashSync(plainSecretId, salt);
    const currentMilliseconds = new Date().getTime();
    var shortHash = hash.slice(7) + currentMilliseconds + generateRandomString(10);

    shortHash = shortHash.replaceAll("/", "backslash");

    const timeGenToken = localStorage.getItem('token_start_at');
    // const [ idleStartTime, setIdleStartTime ] = useState(0);
    
    
    // console.log(new Date().getTime() - parseInt(timeGenToken) );
    // console.log(timeGenToken);
    // console.log(bcrypt.compareSync(token, encodedToken));


    const handleSelectItem = (id) => {
        setMenuItem(id);
        // console.log(id);
    }

    useEffect(() => {

        // console.log(menuItem);

        if (timeGenToken == null || new Date().getTime() - parseInt(timeGenToken) > 60 * 60 * 1000 ) {
            localStorage.removeItem("token");
            localStorage.removeItem("username");
            localStorage.removeItem("token_start_at");
            localStorage.removeItem("session_id");
            nav(`/login/${shortHash}`);
        } 

        const checkSessionExpiration = () => {
            // console.log(new Date().getTime(), idleStartTime, idleStartAt);
            // If the idle time was elapsed 10 minutes, auto terminate the session.
            const isExpired = new Date().getTime() - parseInt(idleStartAt) > 10 * 60 * 1000 && idleStartAt > 0;
            if (isExpired) {
                // console.log("expired");
                // localStorage.removeItem("token");
                // localStorage.removeItem("username");
                // localStorage.removeItem("token_start_at");
                onOpen("success", "Your sesssion has expired, please log in again.")
            }
            // console.log(isActive);
            // console.log(isExpired);
        };

        checkSessionExpiration();

        const intervalId = setInterval(checkSessionExpiration, 60000);

        return () => clearInterval(intervalId);

    // eslint-disable-next-line
    }, [timeGenToken]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            // console.log(document.visibilityState);
            // setIsActive(document.visibilityState === 'visible');
            if (document.hidden) {
                setIsActive(!document.hidden);
                const now = new Date().getTime();
                // console.log(now);
                idleStartAt = now;
                // setIdleStartTime(now);
                // console.log(isActive, idleStartAt);
            } else {
                setTimeout(() => {
                    idleStartAt = 0;
                    // setIdleStartTime(0);
                    setIsActive(true);
                    // console.log(isActive, idleStartAt);
                }, 2000);
            }
            // setIsActive(!document.hidden);
            
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
      
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
        {screenWidth > 780 ? 
            <div className="user-home-container" >
                {
                    <UserLeftMenu handleClickedMenu={handleSelectItem} /> 
                }
                <UserMenuContentContainer item={menuItem}/>
                {/* <p>Item: {menuItem}</p> */}
                {/* <h1>{isActive ? 'Page is active' : 'Page is inactive'}</h1> */}
            </div> :
            <VStack width={"100%"} minHeight={"100vh"}>
                <UserTopMenuMobile handleClickedMenu={handleSelectItem} /> 
                <UserMenuContentContainer item={menuItem}/>
            </VStack>
        }
        </>
            

    );

}

export default UserHomePage;