import { Button, HStack, Input, Text, VStack } from "@chakra-ui/react";
import "./LoginComb.css";
import { useEffect, useState } from "react";
import GoogleSignInComponent from "./GoogleSignInComponent";
import usePost from "../hooks/usePost";
import { useNavigate } from "react-router-dom";
import { useData } from "../context/GlobalContext";


const LoginComb = (props) => {

    const [ isHoverd, setHovered ] = useState(false);
    const [ usrname, setUsrName ] = useState("");
    const [ pwd, setPassword ] = useState("");
    const [ isUserNameTouched, setUserNameTouched ] = useState(false);
    const [ isPwdTouched, setPwdTouched ] = useState(false);
    const [ isForgotHovered, setForgotHorver ] = useState(false);

    const [ showFailedLoginMsg, setFailedLoginMsg ] = useState(false);
    const [ showErrMsg, setErrMsg ] = useState(false);
    const { baseUrl, setGlobalUserName, setGlobalToken, setGlobalSessionId } = useData()

    const nav = useNavigate();

    const { res, submit, isLoading } = usePost();

    const url = baseUrl + "/api/sign_in";

    const handleForgotTextMouseEnter = () => {
        setForgotHorver(true);
    };

    const handleForgotTextMouseLeave = () => {
        setForgotHorver(false);
    }

    const handleSubmit = async () => {

        const currentMilliseconds = new Date().getTime();
        const originalMilliseconds = props.headId.slice(props.headId.length - 23, props.headId.length - 10);
        const elaspedTime = currentMilliseconds - parseInt(originalMilliseconds);

        // The url is valid within 1 minutes
        if (elaspedTime > 60 * 1000 ) {
            nav("/");
        } else {
            setUserNameTouched(true);
            setPwdTouched(true);
            if (usrname !== "" && pwd !== "") {
                // console.log(localStorage.getItem("temp_access_token"));

                const credentials = "Basic " + btoa(usrname + ":" + pwd);
                const header = {'Content-Type': 'application/json', "Auth": credentials, "id": localStorage.getItem("temp_access_token")};
                const bodyDate = {"userName": usrname, "deviceInfo": "web setting"};
                const isCompleted = await submit(url, header, bodyDate);
                // console.log(isCompleted);

                if (isCompleted !== "failed") {
                    
                    const token = isCompleted;
                    setGlobalUserName(usrname);
                    setGlobalToken(token);
                    setGlobalSessionId(props.headId);
                
                    localStorage.setItem('token', token);
                    localStorage.setItem('username', usrname);
                    localStorage.setItem('session_id', props.headId);

                    const tokenGenTime = new Date().getTime();
                    localStorage.setItem('token_start_at', tokenGenTime);       
                    nav("/dashboard")   
                    
                } else {
                    localStorage.removeItem("username");
                    localStorage.removeItem("token");
                    localStorage.removeItem("token_start_at");
                    localStorage.removeItem("session_id");
                    setUsrName("");
                    setPassword("");
                    setUserNameTouched(false);
                    setPwdTouched(false);
                    setFailedLoginMsg(true);
                }
                
                // console.log("handleSubmit: " + usrname + " " + pwd + ", " + isCompleted);
            }
        }
        // console.log(currentMilliseconds - parseInt(originalMilliseconds));
    }

    const handleUserInputChange = (e) => {
        e.preventDefault();
        setUsrName(e.target.value);
        setFailedLoginMsg(false);
    }

    const handlePWDInputChange = (e) => {
        e.preventDefault();
        setPassword(e.target.value);
        setFailedLoginMsg(false);
    }

    const handleUsrBlur = () => {
        setUserNameTouched(true);
    }

    const handlePwdBlur = () => {
        setPwdTouched(true);
    }

    useEffect(() => {
        if (localStorage.getItem('token_start_at') !== null && new Date().getTime() - parseInt(localStorage.getItem('token_start_at')) < 60 * 60 * 1000) {
            setGlobalToken(localStorage.getItem('token'));
            setGlobalUserName(localStorage.getItem('username')); 
            nav("/dashboard")
        } else {
            localStorage.removeItem("token");
            localStorage.removeItem("username");
            localStorage.removeItem("token_start_at");
            localStorage.removeItem("cx_encoded_token");
        }

        if (res.cstatus === 'unauthorized') {
            // console.log("Incorrect");
            setFailedLoginMsg(true);
        } else if (res.cstatus === 'error') {
            setErrMsg(true);
        } 
    // eslint-disable-next-line
    }, [res.cstatus, localStorage]);

    return (
        <div className="login-comb-container">
            <Text color={"lightgray"} fontSize={"3xl"}>Log in</Text>
            <br />
            <HStack spacing={3}>
                <Text color={"lightgray"} >Don’t have an account?</Text>
                <Text className={`underline-on-hover-signup ${isHoverd ? 'hovered' : ''}`}
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        color={"orange"}>Sign up</Text>
            </HStack>
            <br />
            <Input width={"80%"} color={"gray"} placeholder="username" value={usrname} onChange={handleUserInputChange} 
                   onFocus={() => setFailedLoginMsg(false)} onBlur={handleUsrBlur} disabled={isLoading} />
            <br />
            <Input width={"80%"} color={"gray"} type="password" placeholder="password" value={pwd} disabled={isLoading}
                   onChange={handlePWDInputChange} onFocus={() => setFailedLoginMsg(false)} onBlur={handlePwdBlur} />
            <br />
            <VStack spacing={3} 
                    width={"80%"}
                    align={"start"}>
                <Button width={"100%"} height={"37px"} colorScheme="telegram" onClick={handleSubmit} isLoading={isLoading}>Submit</Button>   
                {isUserNameTouched && usrname === "" && <Text fontSize={"sm"} color={"red"}>Username can not be empty.</Text> }
                {isPwdTouched && pwd === "" && <Text fontSize={"sm"} color={"red"}>Password can not be empty.</Text>}
                {showFailedLoginMsg && <Text fontSize={"sm"} color={"red"}>Incorrect username or password</Text>}
                {showErrMsg && <Text fontSize={"sm"} color={"red"}>Error connection, please try again later</Text>}
                <Text color={"#8729ff"} fontSize={"sm"}
                      className={`underline-on-hover ${isForgotHovered ? 'hovered' : ''}`}
                      onMouseEnter={handleForgotTextMouseEnter}
                      onMouseLeave={handleForgotTextMouseLeave}
                      >Forgot your password?</Text>
                <GoogleSignInComponent source="web setting"
                                       headId={props.headId}/>
            </VStack>

        </div>
    );
}

export default LoginComb;