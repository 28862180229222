import { useState } from "react";

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const usePostWithBody = () => {
    const [ result, setResult ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);

    const submitPost = async (url, header, bodyData) => {
        const options = {
            method: 'POST',
            headers: header,
            body: JSON.stringify(bodyData),
        };

        await wait(500);

        setIsLoading(true);

        fetch(url, options)
            .then(response => {
                if (response.status !== 401) {
                    return response.json();
                } else {
                    throw new Error("unauthorized");
                }
            }).then(responseData => {
                setResult(responseData);
            }).catch(error => {
                setResult({
                    cstatus: "010",
                    message: "unauthorized: " + error,
                })
            }).finally ( () => 
                setIsLoading(false)
            );
                
    };

    return { isLoading, result, submitPost };

}

export default usePostWithBody;