import { HStack, Text, Box, VStack, Button } from '@chakra-ui/react';
import leftImage from "../images/account-left.jpg";
import rightImage from "../images/home-bak.jpg";
import "../App.css";
import bcrypt from "bcryptjs-react";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import usePostWithoutBody from '../hooks/usePostWithoutBody';
import { useData } from '../context/GlobalContext';

const Logo = require("../logos/logo-white.png");
const LogoGray = require("../logos/logo-gray.png");

const generateRandomString = (len) => {
    const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~";
    let randomString = "";
    for (let i = 0; i < len; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        randomString += charset[randomIndex];
    }
    return randomString;
}


const HomePage = () => {

    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const nav  = useNavigate();

    const [ localLoading, setLocalLoading ] = useState(false);

    const { isLoading, result, submitWithoutBody } = usePostWithoutBody();
    const { baseUrl } = useData();

    const [ VPNHovered, setVPNHovered ] = useState(false);
    const [ PassHovered, setPassHovered ] = useState(false);
    const [ NoteHovered, setNoteHovered ] = useState(false);
    const [ SignupTextHovered, setSignupTextHovered ] = useState(false);

    // const plainSecretId = 'x*7x!@la~89_+ove%cX&for#2Ev08!@vRW6hYhIu';
    const plainSecretId = process.env.REACT_APP_PLAIN_SECRET_ID;

    const salt = bcrypt.genSaltSync(11);
    const hash = bcrypt.hashSync(plainSecretId, salt);
    const currentMilliseconds = new Date().getTime();
    var shortHash = hash.slice(7) + currentMilliseconds + generateRandomString(10);
    shortHash = shortHash.replaceAll("/", "backslash");

    const handleNavigate = (path) => {
        nav(path);
    }

    useEffect(() => {
        // console.log(result);
        localStorage.setItem("temp_access_token", result.tempToken);
        // console.log(localStorage.getItem("temp_access_token"));
    }, [result]);

    const hanldeLogin = async (path) => {

        const header = {
            "Content-Type": "application/json",
            "id" : shortHash,
        };
        const url = baseUrl + "/api/verify_id/temp_access_token";
        // console.log(url);
        await submitWithoutBody(url, header, "login");
        setLocalLoading(true);
        setTimeout(() => {
            nav(path);
            setLocalLoading(false);
        }, 1000);

    }

    const handleSignUpClicked = async (path) => {
        const header = {
            "Content-Type": "application/json",
            "id" : shortHash,
        };
        const url = baseUrl + "/api/verify_id/temp_access_token";
        await submitWithoutBody(url, header, "signup");
        setTimeout(() => {
            nav(path);
        }, 1000);
    };


    // console.log(currentMilliseconds, currentMilliseconds.toString().length);
    // console.log(hash);
    // console.log(shortHash);
    // console.log(bcrypt.compareSync(plainSecretId, "$2a$11$" + shortHash.slice(0, shortHash.length - 10).replaceAll("backslash", "/")));

    return (
        <div className="home-container">
            <HStack width={"100%"}
                    height={"100%"} 
                    backgroundColor={"#eeeeee"}
                    spacing={0}>
                { screenWidth > 780 && 
                <div className="left-image" 
                     style={{ backgroundImage : `url(${leftImage})`}}>
                    <Box width="100%"
                         height="10%"
                         display={"flex"}
                         paddingLeft={"35px"}
                         justifyContent={"start"}
                         alignItems={"center"} 
                         paddingTop={"15px"} >
                        <button onClick={() => handleNavigate("/")}>
                            <img src={Logo} width={"40px"} alt='logo' />
                        </button>
                        <Text color={"white"} fontSize={"2xl"} fontFamily={"Arial"}>&nbsp;&nbsp;</Text>
                        <Text color={"white"} as={'b'} fontSize={"2xl"} fontFamily={"Arial"}>CNNEX</Text>
                        <Text color={"white"} fontSize={"2xl"} fontFamily={"Arial"}>&nbsp;&nbsp;</Text>
                        <Text color={"white"} fontSize={"2xl"} fontFamily={"Arial"}>Account</Text>
                    </Box>
                    <Box width={"100%"}
                         height={"70%"}
                         display={"flex"}
                         flexDirection={"column"}
                         paddingLeft={"35px"}
                         justifyContent={"center"}
                         alignItems={"start"} 
                         color={"white"}>
                        <Text fontSize={"lg"} 
                              >Manage your setting,</Text>
                        <Text fontSize={"lg"}>subscription, billing, payment,</Text>
                        <Text fontSize={"lg"}>and devices with Cnnex Account.</Text>
                        <br />
                        <Box height={"2px"}
                             width={"15%"}
                             border={"1px solid white"}></Box>
                        <br />
                        <HStack spacing={3}>
                            <Text fontSize={"sm"} 
                                  className={`underline-product ${VPNHovered ? 'hovered' : ''}`}
                                  onMouseEnter={() => setVPNHovered(true)}
                                  onMouseLeave={() => setVPNHovered(false)} >
                                CnnexVPN
                            </Text>
                            <Text fontSize={"sm"} 
                                  className={`underline-product ${PassHovered ? 'hovered' : ''}`}
                                  onMouseEnter={() => setPassHovered(true)}
                                  onMouseLeave={() => setPassHovered(false)}
                                  >CnnexPass</Text>
                            <Text fontSize={"sm"} 
                                  className={`underline-product ${NoteHovered ? 'hovered' : ''}`}
                                  onMouseEnter={() => setNoteHovered(true)}
                                  onMouseLeave={() => setNoteHovered(false)}
                                >CnnextNote</Text>
                        </HStack>
                        {/* <Text fontSize={"sm"}>CnnexVPN&nbsp;&nbsp;&nbsp;&nbsp;CnnexPass&nbsp;&nbsp;&nbsp;&nbsp;CnnexNote</Text> */}
                    </Box>
                </div> }
                <div className= { screenWidth > 780 ? "right-image" : "right-image-mobile" }
                     style={{ backgroundImage : `url(${rightImage})` }}>
                    { screenWidth <= 780 && 
                        <Box width="100%"
                            height="10%"
                            display={"flex"}
                            paddingLeft={"35px"}
                            justifyContent={"start"}
                            alignItems={"center"} 
                            paddingTop={"15px"} >
                            <button onClick={() => handleNavigate("/")}>
                                <img src={LogoGray} width={"40px"} alt='logo' />
                            </button>
                            <Text color={"gray"} fontSize={"2xl"} fontFamily={"Arial"}>&nbsp;&nbsp;</Text>
                            <Text color={"gray"} as={'b'} fontSize={"2xl"} fontFamily={"Arial"}>CNNEX</Text>
                            <Text color={"gray"} fontSize={"2xl"} fontFamily={"Arial"}>&nbsp;&nbsp;</Text>
                            <Text color={"gray"} fontSize={"2xl"} fontFamily={"Arial"}>Account</Text>
                        </Box>
                    }

                    <VStack spacing={7}
                            width={"100%"}
                            height={ screenWidth > 780 ? "100%" : "90%"}
                            paddingLeft={"5%"}
                            align={"start"}
                            justifyContent={"center"} >
                        <VStack spacing={7}
                                width={"100%"}
                                height={screenWidth > 780 ? "90%" : "100%" }
                                align={"start"}
                                justifyContent={"center"}>
                            <Text fontSize={"2xl"} as={'b'} color={"#555555"}>Manager All Your Aplications on Account Center</Text>
                            <Button onClick={() => hanldeLogin(`/login/${shortHash}`)}
                                    width={"310px"} height={"45px"} borderRadius={"22.5px"} colorScheme="telegram"
                                    isLoading={isLoading || localLoading} >Log in to Cnnex Account</Button>
                            <HStack>
                                <Text fontSize={"md"} >No account? </Text>
                                <Text color={"tomato"} as={'b'}
                                    className={`underline-signup ${SignupTextHovered ? 'hovered': ''}`}  
                                    onMouseEnter={() => setSignupTextHovered(true)}
                                    onMouseLeave={() => setSignupTextHovered(false)}
                                    onClick={() => handleSignUpClicked(`/signup/${shortHash}`)}
                                    >Sign up</Text>
                            </HStack>
                        </VStack>
                        {
                            screenWidth > 780 ?
                            <HStack spacing={7}
                                    height={"10%"}
                                    width={"100%"}>
                                <Text fontSize={"sm"}>service@cnnexco.com</Text>
                                <Text fontSize={"sm"}>Privacy policy</Text>
                                <Text fontSize={"sm"}>Terms of service</Text>
                                <Text fontSize={"sm"}>© 2024 Cnnex Limited Company. All rights reserved.</Text>
                            </HStack> :
                            <VStack height={"10%"} align={"start"}>
                                <HStack spacing={4} width={"100%"} >
                                    <Text fontSize={"sm"}>service@cnnexco.com</Text>
                                    <Text fontSize={"sm"}>Privacy policy</Text>
                                    <Text fontSize={"sm"}>Terms of service</Text>
                                </HStack>
                                <Text fontSize={"sm"}>© 2024 Cnnex Limited Company. All rights reserved.</Text>
                            </VStack>
                        }

                    </VStack>
                </div>
            </HStack>
            
        </div>
    )

}

export default HomePage;