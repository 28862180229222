import { useState } from "react";

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const usePost = () => {
    const [ res, setRes ] = useState({});
    const [ isLoading, setIsLoading ]  = useState(false);

    const submit = async (url, headData, data) => {
        var result = {};
        try {
            const optionsWithoutBody = {
                method: 'POST',
                headers: headData,
            };

            const optionsWithBody = {
                method: 'POST',
                headers: headData,
                body: data != null && JSON.stringify(data),
            }
            setIsLoading(true);

            var response;

            if (data === null) {
                response = await fetch(url, optionsWithoutBody);
            } else {
                response = await fetch(url, optionsWithBody);
            }

            await wait(500);

            if (response.status === 401) {
                console.log("Unauthorized");
                setRes({
                    cstatus: 'unauthorized',
                    message: 'Incorrenct credentials',
                })
            } else {
                result = await response.json();
                // console.log(result);
    
                if (!result.cstatus) {
                    throw new Error("Something went wrong");
                }
                setRes(result)
            }

        } catch (error) {
            setRes({
                cstatus: "error",
                message: "something went wrong, please try again later!",
            })
        } finally {
            setIsLoading(false);
        }
        // console.log(result.cst === undefined);
        return result.cst === undefined ? "failed" : result.cst;
    };

    return { res, submit, isLoading };

}

export default usePost;