import { useState } from "react";

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const usePostWithoutBody = () => {
    const [ result, setResult ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);

    const submitWithoutBody = async (url, header, type) => {
        const options = {
            method: "POST",
            headers: header,
        };

        if ( type === "login" ) {
            setIsLoading(true);
        }
        await wait(500);

        fetch(url, options) 
            .then(response => {
                if (response.status !== 401) {
                    return response.json();
                } else {
                    throw new Error("unauthorized");
                }
            }).then(responseData => {
                setResult(responseData);
            }).catch( error => {
                setResult({
                    cstatus: "error",
                    message: "unauthorized" + error.Error,
                });
            }).finally(() => {
                if ( type === "login" ) {
                    setIsLoading(false);
                }
            });
    };

    return { isLoading, result, submitWithoutBody };
}

export default usePostWithoutBody;