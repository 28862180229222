import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleSignIn from "./GoogleSignIn";

const GoogleSignInComponent = (props) => {

    return (
        <div style={{ marginTop: "17px", width: "100%" }} >
            <GoogleOAuthProvider
                clientId="917289489768-bt319df28v0j9hdk5d0lfkdnb0nkm4kt.apps.googleusercontent.com">
                <GoogleSignIn
                    device={props.source}
                    headId={props.headId}
                    width={ "360px" }
                />
            </GoogleOAuthProvider>

        </div>
    );
}

export default GoogleSignInComponent;